/* ==========================================================================
   Author's custom styles
   ========================================================================== */

/* Global classes */
html,body                                                   {font-family: 'Open Sans', sans-serif;}
body                                                        {background-color: #f1f1f1; color: #696969; position: relative;}
h1,h2,h3 													{margin: 0; font-weight: 300;}
a                                                           {color: #04293C; text-decoration: none !important;}
a:hover                                                     {color: #011E2B;}
/**                                                           {-webkit-transition: all 0.3s; transition: all 0.3s;}*/
*:focus                                                     {outline:none !important; -webkit-box-shadow: 0px 0px 0 0px rgba(0, 0, 0, 0) !important; -moz-box-shadow: 0px 0px 0 0px rgba(0, 0, 0, 0) !important; box-shadow: 0px 0px 0 0px rgba(0, 0, 0, 0) !important;}
.no-select                                                  {-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.overlay                                                    {display:none; height: 100%; position: fixed; width: 100%; z-index: 200000; background-color: rgba(0,0,0,0.5); top: 0; left: 0; bottom: 0; right: 0; }
.alert-default                                              {color: #ffffff; background-color: #04293C !important; border-color: #04293C; border-radius: 0; padding: 15px !important;}
section .row 												{padding: 60px 0;}
section .row.no-space 										{padding: 0;}


/* Header */
header                                                      {position: relative; z-index: 1;}
body.model-open header                                      {margin-right: 15px;}
header .logo                                                {display:inline-block; padding: 10px 0;}
header .logo h1 											{color: #f2f2f2; margin: 0; font-size: 0px;}
header > .container > .row                                  {position: relative;}


/* Navigation */
header nav ul                                               {float: right !important;}
header nav ul li > a                                        {padding: 26px 10px 23px !important; color: #adb7be;}
header nav ul li.active > a                                 {color: #ffffff;}
header nav ul li:hover > a                                  {color: #ffffff; font-style: normal; background-color: rgba(238, 238, 238, 0) !important;}
header nav ul li > a:focus                                  {color: #ffffff; font-style: normal; background-color: rgba(238, 238, 238, 0) !important;}
header nav ul li:after  									{content: ''; display: block; margin: auto; height: 2px; width: 0px; background: transparent; transition: width .5s ease, background-color .5s ease;}
header nav ul li:hover:after 	 							{width: 100%; background: #f2f2f2;}


/* Login modal */
#loginPopup .modal-dialog 									{width: 400px; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%) !important;}
#loginPopup h1.title 										{color: #f2f2f2; letter-spacing: 2px; text-align: center; font-size: 4em; margin-bottom: 15px;}
#loginPopup .loginmodal-container 							{padding: 30px; background-color: #f2f3f4; margin: 0 auto; border-radius: 3px; box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);}
#loginPopup .loginmodal-container h2 						{text-align: center; font-size: 2.5em; margin-bottom: 25px;}
#loginPopup .loginmodal-container input[type=text], input[type=password] {height: 44px; -webkit-appearance: none; border: 1px solid #d9d9d9; border-radius: 3px; padding: 0 8px; color: #696969;}
#loginPopup .loginmodal-container input[type=text]:hover, input[type=password]:hover {border: 1px solid #b9b9b9; -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1); -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1); box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);}
#loginPopup label 											{font-weight: lighter; color: #666; display: inline-block; opacity: 0.6; transition: opacity ease 0.5s;}
#loginPopup .form-button .button 							{font-size: 1.2em; padding: 14px 40px; color: #fff; text-transform: uppercase; background: #e5a00d; display: inline-block; outline: 0; border: none; position: relative; transition: all .3s ease; letter-spacing: -.1px; width: 100%; border-radius: 3px;}
#loginPopup .form-button .button:hover 						{background: #f9be03; color: #fff;}


/* Register modal */
#registerPopup .modal-dialog 								{width: 400px; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%) !important;}
#registerPopup h1.title 									{color: #f2f2f2; letter-spacing: 2px; text-align: center; font-size: 4em; margin-bottom: 15px;}
#registerPopup .loginmodal-container 						{padding: 30px; background-color: #f2f3f4; margin: 0 auto; border-radius: 3px; box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);}
#registerPopup .loginmodal-container h2 					{text-align: center; font-size: 2.5em; margin-bottom: 25px;}
#registerPopup .loginmodal-container input[type=text], input[type=password] {height: 44px; -webkit-appearance: none; border: 1px solid #d9d9d9; border-radius: 3px; padding: 0 8px; color: #696969;}
#registerPopup .loginmodal-container input[type=text]:hover, input[type=password]:hover {border: 1px solid #b9b9b9; -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1); -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1); box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);}
#registerPopup label 										{font-weight: lighter; color: #666; display: inline-block; opacity: 0.6; transition: opacity ease 0.5s;}
#registerPopup .form-button .button 						{font-size: 1.2em; padding: 14px 40px; color: #fff; text-transform: uppercase; background: #e5a00d; display: inline-block; outline: 0; border: none; position: relative; transition: all .3s ease; letter-spacing: -.1px; width: 100%; border-radius: 3px;}
#registerPopup .form-button .button:hover 					{background: #f9be03; color: #fff;}


/* Background */
#background                                                 {margin-top: -71px; height: 100vh; position: relative;}
#background.one                                             {background: url('../img/background.jpg') no-repeat center center; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;}
#background.two                                             {background: url('https://www.plex.tv/wp-content/uploads/2015/05/hero-home.jpg') no-repeat center bottom; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;}
#background:before 											{height: 100vh; content: ''; display: block; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: rgba(25,27,29,.6);}

/* Background title welcome */
#background .welcome 										{position: absolute; top: 50%; left: 0; right: 0; transform: translateY(-50%); text-align: center; text-shadow: 0 1px 2px rgba(0,0,0,0.6);}
#background .welcome span.title 							{color: #f2f2f2; letter-spacing: 2px; font-size: 8em;}
#background .welcome:hover span.border:after				{width: 30%; background: #f2f2f2;}
#background .welcome span.border:after						{content: ''; display: block; margin: auto; height: 3px; width: 0px; background: transparent; transition: width .5s ease, background-color .5s ease;}

/* Background caption */
#background .caption 			  				            {position: absolute; left: 0; right: 0; bottom: 50px; text-align: center; text-shadow: 0 1px 2px rgba(0,0,0,0.6);}
#background .caption a  		  				            {color: #f2f2f2; cursor:pointer;}
#background .caption span.title                             {text-transform: uppercase; letter-spacing: 2px; font-size: 1.3em;}
#background .caption span.glyphicon-menu-down				{clear: both; display: block;}


/* Background (break) */
.background                                                 {height: 200px; position: relative;}
.background.break                                           {background: url('../img/background.jpg') no-repeat center center; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;}
.background:before 											{height: 200px; content: ''; display: block; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: rgba(25,27,29,.6);}
/*#background .caption                                        {display: none;}*/
.background.break .row										{padding: 0;}
.background .caption 			  				            {position: absolute; top: 50%; left: 0; right: 0; transform: translateY(-50%); text-align: center; text-shadow: 0 1px 2px rgba(0,0,0,0.6);}
.background .caption span.title                             {color: #f2f2f2; text-transform: uppercase; letter-spacing: 2px; font-size: 2.5em; display: block;}
.background .caption span.moment                            {color: #f2f2f2; text-transform: uppercase; letter-spacing: 2px; font-size: 1.2em; display: block;}

/* Page title below menu */
#web-title                                                  {margin-top: -71px; height: 71px; position: relative;}
#web-title .background 										{height: 71px;}
#web-title .background.break 								{background-position: bottom center;}
#web-title .background .caption 			                {position: relative; transform: initial; text-align: left; text-shadow: 0 1px 2px rgba(0,0,0,0.6); z-index: 2;}
#web-title .background .caption span.title                  {line-height: 71px;}


/* Recently watched */
#recently-watched											{-webkit-transition: all 0.3s; transition: all 0.3s; background-color: #f2f3f4;}
#recently-watched h2 										{text-align: center; font-size: 2.5em; line-height: 1.2em; letter-spacing: -1.25px; padding-bottom: 30px;}
#recently-watched .item-img 								{background-position: center; background-size: cover; height: 400px; width: 100%; position: relative;}
#recently-watched h4 										{overflow: hidden; height: 21px;}
#recently-watched .row.limit 								{padding-top: 0; position: relative; margin-top: -60px;}
/*#recently-watched .row.limit .col-md-3						{padding-bottom: 60px;}*/
#recently-watched .row.limit .close 						{display: none;}
#recently-watched .row.limit .summary 						{display: none;}
#recently-watched .row.limit .focus							{position: absolute; z-index: 1; padding-bottom: 80px; background-color: #f2f2f2;}
#recently-watched .row.limit .focus .thumb					{padding: 0; margin-right: 20px;}
#recently-watched .row.limit .focus h4 						{margin-top: 0px; font-size: 2em; height: auto;}
#recently-watched .row.limit .focus .close 					{display: block; font-size: 3em;}
#recently-watched .row.limit .focus .close .glyphicon		{font-size: 1em;}
#recently-watched .row.limit .focus .summary 				{display: block; padding-top: 20px;}

/* Last Added */
#last-added 												{-webkit-transition: all 0.3s; transition: all 0.3s;}
#last-added	h2 												{text-align: center; font-size: 2.5em; line-height: 1.2em; letter-spacing: -1.25px; padding-bottom: 30px;}
#last-added .item-img 										{background-position: center; background-size: cover; height: 400px; width: 100%; position: relative;}
#last-added	h4 												{overflow: hidden; height: 21px;}
#last-added .row.limit 										{padding-top: 0; position: relative; margin-top: -60px;}
/*#last-added .row.limit .col-md-3							{padding-bottom: 60px;}*/
#last-added .row.limit .close 								{display: none;}
#last-added .row.limit .summary 							{display: none;}
#last-added .row.limit .focus								{position: absolute; z-index: 1; padding-bottom: 80px;}
#last-added .row.limit .focus .thumb						{padding: 0; margin-right: 20px;}
#last-added .row.limit .focus h4 							{margin-top: 0px; font-size: 2em; height: auto;}
#last-added .row.limit .focus .close 						{display: block; font-size: 3em;}
#last-added .row.limit .focus .close .glyphicon				{font-size: 1em;}
#last-added .row.limit .focus .summary 						{display: block; padding-top: 20px;}
#last-added #series											{background-color: #ffffff;}
#last-added #series .row.limit .focus						{background-color: #ffffff;}
#last-added #movies											{background-color: #f2f3f4;}
#last-added #movies .row.limit .focus						{background-color: #f2f3f4;}

/* Most popular */
#popular 													{-webkit-transition: all 0.3s; transition: all 0.3s;}
#popular h2 												{text-align: center; font-size: 2.5em; line-height: 1.2em; letter-spacing: -1.25px; padding-bottom: 30px;}
#popular .item-img 											{background-position: center; background-size: cover; height: 400px; width: 100%; position: relative;}
#popular h4 												{overflow: hidden; height: 21px;}
#popular .row.limit 										{padding-top: 0; position: relative; margin-top: -60px;}
/*#popular .row.limit .col-md-3								{padding-bottom: 60px;}*/
#popular .row.limit .close 									{display: none;}
#popular .row.limit .summary 								{display: none;}
#popular .row.limit .focus									{position: absolute; z-index: 1; padding-bottom: 80px;}
#popular .row.limit .focus .thumb							{padding: 0; margin-right: 20px;}
#popular .row.limit .focus h4 								{margin-top: 0px; font-size: 2em; height: auto;}
#popular .row.limit .focus .close 							{display: block; font-size: 3em;}
#popular .row.limit .focus .close .glyphicon				{font-size: 1em;}
#popular .row.limit .focus .summary 						{display: block; padding-top: 20px;}
#popular #series											{background-color: #ffffff;}
#popular #series .row.limit .focus							{background-color: #ffffff;}
#popular #movies											{background-color: #f2f3f4;}
#popular #movies .row.limit .focus							{background-color: #f2f3f4;}

/* Next release schedule */
#next-release-schedule h2 									{text-align: center; font-size: 2.5em; line-height: 1.2em; letter-spacing: -1.25px; padding-bottom: 30px;}
#next-release-schedule .item-img 							{background-position: center; background-size: cover; height: 400px; width: 100%; position: relative;}
#next-release-schedule h4 									{overflow: hidden; height: 21px;}
#next-release-schedule .row.limit 							{padding-top: 0; position: relative; margin-top: -60px;}
#next-release-schedule .row.limit .close 					{display: none;}
#next-release-schedule .row.limit .summary 					{display: none;}
#next-release-schedule .row.limit .focus					{position: absolute; z-index: 1; padding-bottom: 80px;}
#next-release-schedule .row.limit .focus .thumb				{padding: 0; margin-right: 20px;}
#next-release-schedule .row.limit .focus h4 				{margin-top: 0px; font-size: 2em; height: auto;}
#next-release-schedule .row.limit .focus .close 			{display: block; font-size: 3em;}
#next-release-schedule .row.limit .focus .close .glyphicon	{font-size: 1em;}
#next-release-schedule .row.limit .focus .summary 			{display: block; padding-top: 20px;}
#next-release-schedule #series								{background-color: #ffffff;}
#next-release-schedule #series .row.limit .focus			{background-color: #ffffff;}
#next-release-schedule #movies								{background-color: #f2f3f4;}
#next-release-schedule #movies .row.limit .focus			{background-color: #f2f3f4;}


/* Mobile */
@media (max-width: 767px) {
	/* Navigation */
	.navbar-nav												{margin: 0;}
	.nav>li 												{display: inline-block;}

	/* Recently watched */
	#recently-watched .col-xs-6								{padding-bottom: 15px;}
	#recently-watched .col-md-3:nth-last-of-type(-n+2) 		{display: none;}
	#recently-watched .item-img 							{background-position: center; background-size: cover; height: 250px; width: 100%; position: relative;}
	/* Last Added */
	#last-added	.col-xs-6 									{padding-bottom: 15px;}
	#last-added	.col-md-3:nth-last-of-type(-n+2) 			{display: none;}
	#last-added .item-img 									{background-position: center; background-size: cover; height: 250px; width: 100%; position: relative;}
	/* Popular */
	#popular .col-xs-6 										{padding-bottom: 15px;}
	#popular .col-md-3:nth-last-of-type(-n+2) 				{display: none;}
	#popular .item-img 										{background-position: center; background-size: cover; height: 250px; width: 100%; position: relative;}

	/* Login popup */
	#loginPopup .modal-dialog 								{width: 100%; margin: 0;}
	#loginPopup .modal-content								{border: 0px; border-radius: 0px;}
	#loginPopup .loginmodal-container 						{padding: 30px 15px;}

	/* Background */
	#background                                             {margin-top: -86px;}
}

/* Tablet */
@media (min-width: 768px) and (max-width: 992px) {

}

/* Small Desktop */
@media (min-width: 992px) and (max-width: 1200px) {

}

/* Large Desktop */
@media (min-width: 1200px) {

}


/* ==========================================================================
   no-js overrides
   ========================================================================== */
.browsehappy 									{margin-bottom:10px; background-color:rgb(255, 189, 189); border-top:1px solid red; color:red; border-bottom:1px solid red; padding:5px; display:block; text-align:center; color:red;}
.browsehappy:first-child						{border-top:0;}

.browsehappy-no-js 								{display:none;}
html.no-js .browsehappy-no-js 					{display:block;}


/**
 * Fancybox
 */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp 											{padding: 0;margin: 0;border: 0;outline: none;vertical-align: top;}
.fancybox-wrap 											{position: absolute;top: 0;left: 0;z-index: 8020;}
.fancybox-skin 											{position: relative;background: #f9f9f9;color: #444;text-shadow: none;-webkit-border-radius: 4px;-moz-border-radius: 4px;border-radius: 4px;}
.fancybox-opened 										{z-index: 8030;}
.fancybox-opened .fancybox-skin 						{-webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);-moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);}
.fancybox-outer, .fancybox-inner 						{position: relative;}
.fancybox-inner 										{overflow: hidden;}
.fancybox-type-iframe .fancybox-inner 					{-webkit-overflow-scrolling: touch;}
.fancybox-error 										{color: #444;font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;margin: 0;padding: 15px;white-space: nowrap;}
.fancybox-image, .fancybox-iframe 						{display: block;width: 100%;height: 100%;}
.fancybox-image 										{max-width: 100%;max-height: 100%;}
#fancybox-loading, .fancybox-close, 
.fancybox-prev span, 
.fancybox-next span 									{background-image: url('../img/fancybox/fancybox_sprite.png');}
#fancybox-loading 										{position: fixed;top: 50%;left: 50%;margin-top: -22px;margin-left: -22px;background-position: 0 -108px;opacity: 0.8;cursor: pointer;z-index: 8060;}
#fancybox-loading div 									{width: 44px;height: 44px;background: url('../img/fancybox/fancybox_loading.gif') center center no-repeat;}
.fancybox-close 										{position: absolute;top: -18px;right: -18px;width: 36px;height: 36px;cursor: pointer;z-index: 8040;}
.fancybox-nav 											{position: absolute;top: 0;width: 40%;height: 100%;cursor: pointer;text-decoration: none;background: transparent url('../img/fancybox/blank.gif'); /* helps IE */-webkit-tap-highlight-color: rgba(0,0,0,0);z-index: 8040;}
.fancybox-prev 											{left: 0;}
.fancybox-next 											{right: 0;}
.fancybox-nav span 										{position: absolute;top: 50%;width: 36px;height: 34px;margin-top: -18px;cursor: pointer;z-index: 8040;visibility: hidden;}
.fancybox-prev span 									{left: 10px;background-position: 0 -36px;}
.fancybox-next span 									{right: 10px;background-position: 0 -72px;}
.fancybox-nav:hover span 								{visibility: visible;}
.fancybox-tmp 											{position: absolute;top: -99999px;left: -99999px;visibility: hidden;max-width: 99999px;max-height: 99999px;overflow: visible !important;}

/* Overlay helper */
.fancybox-lock 											{overflow: hidden !important;width: auto;}
.fancybox-lock body 									{overflow: hidden !important;}
.fancybox-lock-test 									{overflow-y: hidden !important;}
.fancybox-overlay 										{position: absolute;top: 0;left: 0;overflow: hidden;display: none;z-index: 8010;background: url('../img/fancybox/fancybox_overlay.png');}
.fancybox-overlay-fixed 								{position: fixed;bottom: 0;right: 0;}
.fancybox-lock .fancybox-overlay 						{z-index:99999; overflow: auto;overflow-y: scroll;}

/* Title helper */
.fancybox-title 										{visibility: hidden;font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;position: relative;text-shadow: none;z-index: 8050;}
.fancybox-opened .fancybox-title 						{visibility: visible;}
.fancybox-title-float-wrap 								{position: absolute;bottom: 0;right: 50%;margin-bottom: -35px;z-index: 8050;text-align: center;}
.fancybox-title-float-wrap .child 						{display: inline-block;margin-right: -100%;padding: 2px 20px;background: transparent; /* Fallback for web browsers that doesn't support RGBa */background: rgba(0, 0, 0, 0.8);-webkit-border-radius: 15px;   -moz-border-radius: 15px;        border-radius: 15px;text-shadow: 0 1px 2px #222;color: #FFF;font-weight: bold;line-height: 24px;white-space: nowrap;}
.fancybox-title-outside-wrap 							{position: relative;margin-top: 10px;color: #fff;}
.fancybox-title-inside-wrap 							{padding-top: 10px;}
.fancybox-title-over-wrap 								{position: absolute;bottom: 0;left: 0;color: #fff;padding: 10px;background: #000;background: rgba(0, 0, 0, .8);}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	   only screen and (min--moz-device-pixel-ratio: 1.5),
	   only screen and (min-device-pixel-ratio: 1.5){

		#fancybox-loading, 
		.fancybox-close, 
		.fancybox-prev span, 
		.fancybox-next span 							{background-image: url('../img/fancybox/fancybox_sprite@2x.png');background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/}
		#fancybox-loading div 							{background-image: url('../img/fancybox/fancybox_loading@2x.gif');background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/}
}